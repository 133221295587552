/* eslint-disable jsx-a11y/media-has-caption */
import { i18n } from '@lingui/core';
import clsx from 'clsx';
import Image from 'next/image';
import Script from 'next/script';
import type { SyntheticEvent } from 'react';
import { useId, useRef, useState } from 'react';
import IconPlay from '../../../icons/misc/play.svg';
import styles from './VideoPlayer.module.scss';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Vimeo {
  class Player {
    constructor(id: string, options: Record<string, unknown>);
    ready: () => Promise<void>;
    play: () => void;
  }
}

export type ImageType = {
  url: string;
  title: string;
  height: number;
  width: number;
};

export type VideoProp = {
  url: string;
  contentType: string;
};

export type VideoPlayerProps = {
  video?: VideoProp;
  youtubeVideoId?: string;
  vimeoVideoId?: string;
  poster: ImageType;
  className?: string;
  onPlay?: () => void;
  coverPoster?: boolean;
};

/**
 * Video Player component.
 * @param props - Component props.
 */
export const VideoPlayer = ({
  video,
  youtubeVideoId,
  vimeoVideoId,
  poster,
  className,
  onPlay,
  isLoading,
  coverPoster,
}: VideoPlayerProps & { isLoading?: boolean }) => {
  const [videoPlayed, setVideoPlayed] = useState(false);
  const id = useId();
  const videoPlayer = useRef<HTMLElement>(null);

  const playYoutubeVideo = () => {
    /**
     * Youtube API will call this function when the page has finished
     * downloading the JavaScript for the player API.
     */
    function onYouTubeIframeAPIReady() {
      new YT.Player(`${id}-external_video`, {
        videoId: youtubeVideoId,
        playerVars: {
          playsinline: 1,
        },
        events: {
          onReady: onPlayerReady,
        },
      });
    }

    /**
     * Function to execute as soon as the player is ready.
     * Plays video and removes loading css class.
     */
    const onPlayerReady = (event: YT.PlayerEvent) => {
      event.target.playVideo();
      videoPlayer.current?.classList.remove('is-loading');
      onPlay && onPlay();
    };

    //
    if (typeof YT === 'object' && typeof YT.Player === 'function') {
      onYouTubeIframeAPIReady();
      return;
    }

    // Exposing function onYouTubeIframeAPIReady to global context
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
  };

  const playVimeoVideo = () => {
    const options = {
      id: vimeoVideoId,
    };

    const player = new Vimeo.Player(`${id}-external_video`, options);

    player.ready().then(() => {
      // The player is ready
      player.play();
      videoPlayer.current?.classList.remove('is-loading');
      onPlay && onPlay();
    });
  };

  /**
   * Handle canplay event.
   * @param event - canplay event.
   */
  const handleCanPlay = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    const video = event.target as HTMLVideoElement;
    video.play();
    onPlay && onPlay();
  };

  if (isLoading) {
    return (
      <section
        className={[styles.videoPlayer, 'is-loading'].join(' ')}
      ></section>
    );
  }

  if (video?.url) {
    return (
      <section
        className={styles.videoPlayer}
        ref={videoPlayer}
        style={{ aspectRatio: poster.width / poster.height }}
      >
        {videoPlayed ? (
          <video
            className={styles.videoPlayer__el}
            controls
            playsInline
            onCanPlay={handleCanPlay}
          >
            <source src={video.url} type={video.contentType} />
          </video>
        ) : (
          <VideoPlaceholder
            playHandler={() => setVideoPlayed(true)}
            poster={poster}
            className={className}
            coverPoster={coverPoster}
          />
        )}
      </section>
    );
  }

  if (youtubeVideoId || vimeoVideoId) {
    return (
      <section
        className={clsx(styles.videoPlayer, videoPlayed && 'is-loading')}
        ref={videoPlayer}
        style={{ aspectRatio: poster.width / poster.height }}
      >
        {videoPlayed ? (
          <div
            id={`${id}-external_video`}
            className={styles.videoPlayer__embed}
          >
            <Script
              src={
                youtubeVideoId
                  ? 'https://www.youtube.com/iframe_api'
                  : 'https://player.vimeo.com/api/player.js'
              }
              onReady={youtubeVideoId ? playYoutubeVideo : playVimeoVideo}
            />
          </div>
        ) : (
          <VideoPlaceholder
            playHandler={() => setVideoPlayed(true)}
            poster={poster}
            className={className}
            coverPoster={coverPoster}
          />
        )}
      </section>
    );
  }

  return <></>;
};

/**
 * Video placeholder.
 * When the button clicked it adds the video element/iframe to dom.
 */
export const VideoPlaceholder = ({
  playHandler,
  poster,
  className,
  coverPoster,
}: {
  playHandler: () => void;
  poster: ImageType;
  className: string | undefined;
  coverPoster?: boolean;
}) => {
  return (
    <>
      <button
        type="button"
        className={clsx(styles.videoPlayer__button, className)}
        aria-label={i18n._(
          /* i18n */ {
            id: 'accessibility.playVideo',
            message: 'Click to play video',
          }
        )}
        onClick={playHandler}
      >
        <IconPlay />
      </button>
      <Image
        src={poster.url}
        fill={true}
        alt={poster.title}
        title={poster.title}
        sizes="(max-width: 1024px) 100vw, 887px"
        className={clsx(styles.videoPlayer__poster, {
          [styles.coverPoster]: coverPoster,
        })}
      />
    </>
  );
};
